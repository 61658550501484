import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Contact from './components/contact';

function App() {
  return (
    <BrowserRouter>
      <div>
        <div className='pt-10'>
          <Navbar />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
