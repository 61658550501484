import React from 'react';
import { motion } from "framer-motion";
import EcoQuestImage from "../assets/ecoquest.png";
import BingoAiImage from "../assets/gobingo.png";
import GramwayImage from "../assets/gramway.jpeg";
import InsightfulAiImage from "../assets/insightai.jpeg";

function Works() {
    // const [activeFilter, setActiveFilter] = useState('all');

    const projects = [
        {
            title: "EcoQuest",
            category: "Mobile App",
            description: "AI-driven sustainability companion app",
            image: EcoQuestImage,  // Use the imported image
            tags: ["React Native", "AI", "Python"],
            link: "/works/ecoquest"
        },
        {
            title: "GoBingo AI",
            category: "AI Chatbot",
            description: "GoBingo is an AI-powered chatbot designed to automate the processing and information extraction from identification documents. It supports multiple document types and uses advanced Vision Language Models (VLM) for accurate text extraction.",
            image: BingoAiImage,  // Use the imported image
            tags: ["Python", "Flask", "Machine Learning"],
            link: "/works/bingoai"
        },  
        {
            title: "Insightful AI",
            category: "Web App AI",
            description: "A tool that allows users to upload files and ask specific questions, providing instant, context-aware answers that enhance comprehension and decision-making.",
            image: InsightfulAiImage,  // Use the imported image
            tags: ["NextJs", "TailwindCSS", "Gemini AI"],
            link: "/works/insightfulai"
        }, 
        {
            title: "Gramway",
            category: "Website",
            description: "E-commerce platform redesign",
            image: GramwayImage,  // Use the imported image
            tags: ["Next.js", "Tailwind", "Stripe"],
            link: "/works/gramway"
        }
    ];



    return (
        <section className="py-24 bg-black relative overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
            <div className="absolute right-0 top-20 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
            
            <div className="max-w-7xl mx-auto px-6 relative">
                {/* Header */}
                <div className="text-center mb-16">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="inline-flex items-center px-3 py-1.5 rounded-full border border-purple-500/20 bg-purple-500/5 mb-6"
                    >
                        <span className="text-sm text-purple-300 font-medium">Our Works</span>
                    </motion.div>
                    
                    <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.2 }}
                        className="text-4xl md:text-5xl font-bold mb-6"
                    >
                        Featured
                        <span className="bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent"> Projects</span>
                    </motion.h2>
                </div>

                {/* Projects Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projects.map((project, index) => (
                        <motion.div
                            key={project.title}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.1 }}
                        >
                            <a href={project.link} className="group block">
                                <div className="relative overflow-hidden rounded-xl bg-gray-900/50 border border-white/5">
                                    {/* Project Image */}
                                    <div className="aspect-video overflow-hidden">
                                        <img
                                            src={project.image}
                                            alt={project.title}
                                            className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                                        />
                                    </div>
                                    
                                    {/* Project Info */}
                                    <div className="p-6">
                                        <span className="text-sm text-purple-400 font-medium">
                                            {project.category}
                                        </span>
                                        <h3 className="text-xl font-semibold text-white mt-2 mb-3">
                                            {project.title}
                                        </h3>
                                        <p className="text-gray-400 text-sm mb-4">
                                            {project.description}
                                        </p>
                                        
                                        {/* Tags */}
                                        <div className="flex flex-wrap gap-2">
                                            {project.tags.map((tag) => (
                                                <span
                                                    key={tag}
                                                    className="px-3 py-1 text-xs font-medium bg-white/5 text-gray-300 rounded-full"
                                                >
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Works;