import React from 'react';
import { motion } from 'framer-motion';

function Contact() {
    const socialLinks = [
        {
            name: 'LinkedIn',
            url: 'https://www.linkedin.com/company/craftora/',
            icon: (
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
            )
        },
        {
            name: 'Facebook',
            url: 'https://www.facebook.com/profile.php?id=61567303811833',
            icon: (
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
            )
        },
        {
            name: 'Email',
            url: 'mailto:craftora.work@gmail.com',
            icon: (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                </svg>
            )
        }
    ];

    return (
        <section className="relative py-32 bg-black overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
            <div className="absolute right-0 top-20 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
            
            <div className="max-w-7xl mx-auto px-6 relative">
                {/* Header */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-20"
                >
                    <span className="inline-block px-4 py-1.5 rounded-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/20 text-purple-300 text-sm mb-4">
                        Get in Touch
                    </span>
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                        Connect With <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">Us</span>
                    </h2>
                    <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                        Reach out to us through any of our social channels. We'd love to hear from you!
                    </p>
                </motion.div>

                {/* Social Links Grid */}
                <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
                    {socialLinks.map((social, index) => (
                        <motion.div
                            key={social.name}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.1 }}
                        >
                            <a
                                href={social.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block p-8 rounded-2xl bg-white/5 border border-white/10 hover:bg-white/10 transition-all duration-300 group"
                            >
                                <div className="flex flex-col items-center text-center">
                                    <div className="w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-r from-purple-500/20 to-blue-500/20 mb-4 group-hover:scale-110 transition-transform duration-300">
                                        <span className="text-purple-400 group-hover:text-purple-300 transition-colors">
                                            {social.icon}
                                        </span>
                                    </div>
                                    <h3 className="text-xl font-semibold text-white mb-2">{social.name}</h3>
                                    <p className="text-gray-400 text-sm">Connect with us on {social.name}</p>
                                </div>
                            </a>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Contact; 