import React from 'react';
import { motion } from 'framer-motion';

function Services() {
    const services = [
        {
            icon: "🎨",
            title: "UI/UX Design",
            description: "Creating intuitive and engaging user experiences that delight your customers",
            features: ["User Research", "Wireframing", "Prototyping", "Visual Design"]
        },
        {
            icon: "💻",
            title: "Web Development",
            description: "Building responsive and performant web applications with modern technologies",
            features: ["Frontend Development", "Backend Systems", "API Integration", "Performance Optimization"]
        },
        {
            icon: "📱",
            title: "Mobile Development",
            description: "Crafting native and cross-platform mobile applications for iOS and Android",
            features: ["iOS Development", "Android Development", "React Native", "Flutter"]
        },
        {
            icon: "🤖",
            title: "AI Solutions",
            description: "Implementing cutting-edge AI and machine learning solutions for your business",
            features: ["Machine Learning", "Natural Language Processing", "Computer Vision", "Predictive Analytics"]
        }
    ];

    return (
        <section className="relative py-32 bg-black overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
            <div className="absolute right-0 top-1/2 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
            
            <div className="max-w-7xl mx-auto px-6 relative">
                {/* Header */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-20"
                >
                    <span className="inline-block px-4 py-1.5 rounded-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/20 text-purple-300 text-sm mb-4">
                        Our Services
                    </span>
                    <h2 className="text-4xl md:text-5xl font-bold text-white">
                        Comprehensive <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">Digital Solutions</span>
                    </h2>
                </motion.div>

                {/* Services Grid */}
                <div className="grid md:grid-cols-2 gap-8">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.2 }}
                            className="group relative p-8 rounded-2xl bg-white/5 border border-white/10 hover:bg-white/10 transition-all duration-300"
                        >
                            <div className="flex items-start gap-6">
                                <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gradient-to-r from-purple-500/20 to-blue-500/20">
                                    <span className="text-2xl">{service.icon}</span>
                                </div>
                                <div className="flex-1">
                                    <h3 className="text-xl font-semibold text-white mb-3">{service.title}</h3>
                                    <p className="text-gray-400 mb-4">{service.description}</p>
                                    <div className="grid grid-cols-2 gap-2">
                                        {service.features.map((feature, idx) => (
                                            <div key={idx} className="flex items-center gap-2">
                                                <div className="w-1.5 h-1.5 rounded-full bg-purple-500"></div>
                                                <span className="text-sm text-gray-400">{feature}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Services; 