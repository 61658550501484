import React from 'react';
import { motion } from 'framer-motion';
import Works from '../components/works'
import Team from '../components/team';
import AboutUs from '../components/aboutus';
import WhyUs from '../components/whyus';
import Services from '../components/services';

function Home() {
    return (
        <>
            <section className="relative min-h-screen flex items-center justify-center bg-black overflow-hidden">
                {/* Background Elements */}
                <div className="absolute inset-0">
                    <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
                    <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[500px] h-[500px] bg-gradient-to-r from-purple-500/30 to-blue-500/30 rounded-full blur-3xl opacity-20"></div>
                </div>

                <div className="relative z-10 max-w-7xl mx-auto px-6 py-32">
                    <div className="flex flex-col items-center text-center space-y-12">
                        {/* Status Badge */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                            className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/20"
                        >
                            <span className="relative flex h-2 w-2">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
                            </span>
                            <span className="text-purple-200 text-sm font-medium">Available for Projects</span>
                        </motion.div>

                        {/* Main Heading */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.2 }}
                            className="space-y-6"
                        >
                            <h1 className="text-5xl md:text-7xl font-bold leading-tight">
                                <span className="bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                                    Crafting Digital
                                </span>
                                <br />
                                <span className="text-white">Experiences</span>
                            </h1>
                            
                            <p className="text-gray-400 text-lg md:text-xl max-w-2xl mx-auto leading-relaxed">
                                We transform ideas into exceptional digital products through strategic design and development.
                            </p>
                        </motion.div>

                        {/* CTA Buttons */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.4 }}
                            className="flex flex-col sm:flex-row gap-4 justify-center"
                        >
                            <button className="group px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300">
                                View Our Work
                                <svg className="inline-block w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </button>
                            <button className="px-8 py-4 rounded-full border border-gray-700 text-white hover:bg-white/5 transition-all">
                                Contact Us
                            </button>
                        </motion.div>

                        {/* Scroll Indicator */}
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.6, delay: 0.8 }}
                            className="absolute bottom-12 left-1/2 -translate-x-1/2"
                        >
                            <div className="w-6 h-10 rounded-full border-2 border-gray-500 flex items-start justify-center p-1">
                                <motion.div
                                    animate={{ 
                                        y: [0, 12, 0],
                                    }}
                                    transition={{ 
                                        duration: 1.5,
                                        repeat: Infinity,
                                        repeatType: "loop"
                                    }}
                                    className="w-1.5 h-1.5 rounded-full bg-purple-500"
                                />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Why Choose Us Section */}
            <WhyUs />
            
            {/* Services Section */}
            <Services />
            
            {/* About Us Section */}
            <AboutUs />

            {/* Our Process Section */}
            <section className="relative py-32 bg-black overflow-hidden">
                <div className="absolute right-0 bottom-0 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl"></div>
                
                <div className="max-w-7xl mx-auto px-6 relative">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="text-center mb-20"
                    >
                        <span className="inline-block px-4 py-1.5 rounded-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/20 text-purple-300 text-sm mb-4">
                            Our Process
                        </span>
                        <h2 className="text-4xl md:text-5xl font-bold text-white">
                            How We <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">Work</span>
                        </h2>
                    </motion.div>

                    <div className="grid md:grid-cols-4 gap-8">
                        {[
                            { number: "01", title: "Discovery", description: "Understanding your needs" },
                            { number: "02", title: "Strategy", description: "Planning the approach" },
                            { number: "03", title: "Design", description: "Creating the solution" },
                            { number: "04", title: "Development", description: "Bringing ideas to life" }
                        ].map((step, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                                className="relative group"
                            >
                                <div className="absolute -left-4 top-0 text-4xl font-bold text-white/10 group-hover:text-purple-500/20 transition-colors">
                                    {step.number}
                                </div>
                                <div className="pt-8 pl-8 border-l border-white/10 group-hover:border-purple-500/50 transition-colors">
                                    <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
                                    <p className="text-gray-400">{step.description}</p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Works Section */}
            <Works />

            {/* Team Section */}
            <Team />

            {/* Contact Section */}
            <section className="relative py-32 bg-black overflow-hidden">
                <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
                <div className="absolute left-0 top-0 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
                
                <div className="max-w-7xl mx-auto px-6 relative">
                    <div className="max-w-3xl mx-auto text-center">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            className="space-y-8"
                        >
                            <span className="inline-block px-4 py-1.5 rounded-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/20 text-purple-300 text-sm">
                                Get in Touch
                            </span>
                            <h2 className="text-4xl md:text-5xl font-bold text-white">
                                Start Your Next <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">Project</span>
                            </h2>
                            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                                Ready to transform your digital presence? Let's create something amazing together.
                            </p>
                            <button className="px-8 py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300">
                                Let's Talk
                            </button>
                        </motion.div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
