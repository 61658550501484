import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../logo/craftora.png';

function Footer() {
    const currentYear = new Date().getFullYear();

    const footerLinks = {
        Services: ['Web Development', 'Mobile Apps', 'UI/UX Design', 'AI Solutions'],
        Connect: [
            { name: 'LinkedIn', url: 'https://www.linkedin.com/company/craftora/' },
            { name: 'Facebook', url: 'https://www.facebook.com/profile.php?id=61567303811833' },
            { name: 'GitHub', url: 'https://github.com/Craftora' }
        ]
    };

    return (
        <footer className="relative bg-black border-t border-white/10">
            {/* Background Elements */}
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
            <div className="absolute left-0 bottom-0 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
            
            <div className="relative max-w-7xl mx-auto px-6 py-16">
                <div className="grid grid-cols-2 md:grid-cols-6 gap-8 mb-16">
                    {/* Logo & Description */}
                    <div className="col-span-2">
                        <Link to="/" className="flex items-center space-x-2 mb-6">
                            <img src={Logo} alt="Craftora" className="h-10 w-10" />
                            <span className="text-white font-bold text-xl">Craftora</span>
                        </Link>
                        <p className="text-gray-400 mb-6">
                            Crafting exceptional digital experiences through innovative design and development solutions.
                        </p>
                        <div className="flex space-x-4">
                            {['twitter', 'linkedin', 'github', 'instagram'].map((social) => (
                                <a
                                    key={social}
                                    href={social === 'linkedin' ? 'https://www.linkedin.com/company/craftora/' : `https://${social}.com`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="w-10 h-10 rounded-full bg-white/5 flex items-center justify-center hover:bg-white/10 transition-colors"
                                >
                                    <span className="sr-only">{social}</span>
                                </a>
                            ))}
                        </div>
                    </div>

                    {/* Links */}
                    {Object.entries(footerLinks).map(([category, links]) => (
                        <div key={category} className="col-span-1">
                            <h3 className="text-white font-semibold mb-4">{category}</h3>
                            <ul className="space-y-3">
                                {Array.isArray(links) ? (
                                    links.map((link, index) => (
                                        <li key={index}>
                                            {typeof link === 'string' ? (
                                                <a href="#services" className="text-gray-400 hover:text-white transition-colors">
                                                    {link}
                                                </a>
                                            ) : (
                                                <a 
                                                    href={link.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer" 
                                                    className="text-gray-400 hover:text-white transition-colors"
                                                >
                                                    {link.name}
                                                </a>
                                            )}
                                        </li>
                                    ))
                                ) : null}
                            </ul>
                        </div>
                    ))}
                </div>

                {/* Bottom Bar */}
                <div className="pt-8 border-t border-white/10 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                    <div className="text-gray-400 text-sm">
                        © {currentYear} Craftora. All rights reserved.
                    </div>
                    <div className="flex space-x-6 text-sm">
                        <Link to="/privacy" className="text-gray-400 hover:text-white transition-colors">
                            Privacy Policy
                        </Link>
                        <Link to="/terms" className="text-gray-400 hover:text-white transition-colors">
                            Terms of Service
                        </Link>
                        <Link to="/cookies" className="text-gray-400 hover:text-white transition-colors">
                            Cookie Policy
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer; 