import React from "react";
import TeamPic1 from '../assets/team1.jpg';
import { motion } from 'framer-motion';

function AboutUs() {
    const achievements = [
        {
            number: "4+",
            label: "Years",
            description: "Of Excellence"
        },
        {
            number: "33",
            label: "Projects",
            description: "Delivered Successfully"
        },
        {
            number: "30+",
            label: "Clients",
            description: "Worldwide"
        },
    ];

    return (
        <section className="relative py-24 md:py-32 bg-black overflow-hidden">
            {/* Background Elements */}
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>
            <div className="absolute right-0 top-20 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
            <div className="absolute left-0 bottom-20 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl"></div>

            <div className="relative max-w-7xl mx-auto px-6">
                <div className="grid lg:grid-cols-2 gap-16 items-center">
                    {/* Left Column - Text Content */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6 }}
                        className="space-y-8"
                    >
                        <div className="inline-flex items-center px-3 py-1.5 rounded-full border border-purple-500/20 bg-purple-500/5">
                            <span className="relative flex h-2 w-2 mr-2">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
                            </span>
                            <span className="text-sm text-purple-300 font-medium">About Our Journey</span>
                        </div>

                        <h2 className="text-4xl md:text-5xl font-bold leading-tight">
                            Crafting Digital 
                            <span className="block mt-2 bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                                Experiences That Matter
                            </span>
                        </h2>

                        <p className="text-gray-400 text-lg leading-relaxed">
                            We're a team of passionate creators and problem solvers, dedicated to transforming ideas into exceptional digital experiences. Our approach combines creativity with strategic thinking to deliver solutions that make an impact.
                        </p>

                        <div className="flex flex-wrap gap-6">
                            {achievements.map((item, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.6, delay: index * 0.1 }}
                                    className="flex-1 min-w-[140px]"
                                >
                                    <div className="text-3xl font-bold bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent mb-1">
                                        {item.number}
                                    </div>
                                    <div className="text-white font-medium mb-1">{item.label}</div>
                                    <div className="text-gray-400 text-sm">{item.description}</div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>

                    {/* Right Column - Image & Cards */}
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}
                        className="relative"
                    >
                        <div className="relative rounded-2xl overflow-hidden group">
                            <img 
                                src={TeamPic1} 
                                alt="Our Team" 
                                className="w-full h-[500px] object-cover transform group-hover:scale-105 transition-transform duration-700"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent"></div>
                            
                            {/* Floating Card */}
                            <div className="absolute bottom-6 left-6 right-6 bg-white/10 backdrop-blur-xl p-6 rounded-xl border border-white/10">
                                <h3 className="text-xl font-semibold text-white mb-2">Our Mission</h3>
                                <p className="text-gray-300 text-sm leading-relaxed">
                                    To deliver innovative digital solutions that empower businesses and create meaningful user experiences.
                                </p>
                            </div>
                        </div>

                        {/* Decorative Elements */}
                        <div className="absolute -top-4 -right-4 w-24 h-24 bg-purple-500/20 rounded-full blur-2xl"></div>
                        <div className="absolute -bottom-4 -left-4 w-32 h-32 bg-blue-500/20 rounded-full blur-2xl"></div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;